export default {
  "YYTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY Turnkey Solution"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首页"])},
  "GameCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品介绍"])},
  "PlatformDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["平台展示"])},
  "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免费咨询"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中文"])},
  "CustomizedEntertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["订制娱乐城"])},
  "CustomizedEntertainmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY Turneky Solution, 领先的在线赌场平台，一站式包网服务，游戏完整建站，打造完全客制化品牌。提供多年的专业知识、尖端技术、独家游戏和先进工具"])},
  "The3StepProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["简单三步骤"])},
  "CustmizedFrontEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["迅速开站"])},
  "BackOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["后台系统"])},
  "AffiliateProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代理系统"])},
  "Step1Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01 – CUSTOMIZED"])},
  "Step1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专人咨询"])},
  "Step1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们会让你所有精彩的想法发挥作用！通过我们的专家和您的投入，我们不仅可以为您创建最合适的平台，还可以帮助您处理其他事务，如为您的公司设计徽标。"])},
  "Step2Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02 – WEB VERSION"])},
  "Step2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择模板"])},
  "Step2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["根据您的需求，选择或订制版型。本产品不管是桌机用户、平板用户、手机用户皆可畅行无阻，若您有需要还可以制作手机客户端。"])},
  "Step3Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03 - GAMES"])},
  "Step3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["选择游戏"])},
  "Step3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["与各大知名游戏品牌合作，汇集超过25家以上平台，游戏从经典真人百家乐游戏至电竞竞猜，千款游戏任意畅玩，欢迎您前往试玩。"])},
  "ProductAdvantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["产品优势"])},
  "ProductAdvantagesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们拥有专业的技术团队，为您打造最合适的娱乐城"])},
  "Advantages1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["一站式解决方案"])},
  "Advantages1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["业界最丰富的博弈经验,由专业团队满足个性化定制需求,提供一站式集成整合服务。"])},
  "Advantages2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多样化游戏平台"])},
  "Advantages2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提供多种游戏多元化，严格把关游戏合作商，高质量公正公平的游戏厂商集成接入，让您营运安枕无忧。"])},
  "Advantages3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多种代理模式"])},
  "Advantages3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["多种代理模式：金字塔代理模式，实时结算层层抽佣，网站营运轻而易举，快速赚进大把钞票；全民代理模式，时下最为热门的营销手段，每个玩家都能成为站点代理。"])},
  "Advantages4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["快速便利支付"])},
  "Advantages4Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持网银、云闪付、微信、支付宝等等第三方支付，会员存款自动上分，快速便利更有保障。简单的操作接口、高弹性的自定义限额，有效管理会员入款金额。"])},
  "Advantages5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全平台支持"])},
  "Advantages5Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["支持各种计算机、行动装置，玩游戏无需下载/安装任何手机程序。汇集多个平台的移动端设备，完美适配Android和iOS两大系统。"])},
  "Advantages6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["专业数据分析"])},
  "Advantages6Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不断更新后台功能，与业界时时接轨，计算各种资金、游戏数据，图像展示报表，随时动态掌握，让您可以更轻松且聪明的管理娱乐城。"])},
  "Advantages7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["安全防护"])},
  "Advantages7Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["通过Global Trust国际安全认证，采AES 256位加密，保证大用户量访问，提升数据安全性。免停机的维护方式，以及不断的更新功能，使用起来更加顺畅，并且保障您的网页稳定。"])},
  "Advantages8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDN加速服务"])},
  "Advantages8Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["域名申请、架设网站的琐事您都不必烦恼，这些琐事我们将会为您服务。搭配CDN服务，加速网站，提高系统的稳定性。"])},
  "Advantages9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7技术客服"])},
  "Advantages9Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我们拥有专业的24小时不间断在线技术客服，第一时间处理各类突发事件解决各类问题及答覆。"])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作伙伴"])},
  "Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["老虎机"])},
  "LiveCasino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["真人视讯"])},
  "Sportsbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["体育博彩"])},
  "eSabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["斗鸡直播"])},
  "eGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电子游戏"])},
  "Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["电脑版"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手机版"])}
}