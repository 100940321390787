import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '../views/elements/404.vue')
    },
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    },
    {
        path: '/ProductDemo',
        name: 'ProductDemo',
        component: () => import(/* webpackChunkName: "HomeSass" */ '../views/ProductDemo.vue'),
    },
    {
        path: '/Games',
        name: 'Games',
        component: () => import(/* webpackChunkName: "HomeAppLanding" */ '../views/Games.vue'),
    },

];
  
const router = createRouter({
    history: createWebHistory(),
    routes,
});
  
export default router;
