export default {
  "YYTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY Turnkey Solution"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOME"])},
  "GameCollections": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Game Collections"])},
  "PlatformDemo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platform Demo"])},
  "ContactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact Us"])},
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
  "CustomizedEntertainment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customized Entertainment"])},
  "CustomizedEntertainmentDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YY Turneky Solution, the leading online casino platform offering years of expertise, cutting-edge technology, exclusive games, and advanced tools."])},
  "The3StepProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The 3 - step process"])},
  "CustmizedFrontEnd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custmized Front End"])},
  "BackOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Office"])},
  "AffiliateProgram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiliate Program"])},
  "Step1Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["01 – CUSTOMIZED"])},
  "Step1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Consultation"])},
  "Step1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We’ll make all your brilliant ideas work! With the combination of our specialists and your inputs, not only can we create the most suitable platform for you, but can also assist you with other matters such as designing a logo for your company."])},
  "Step2Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["02 – WEB VERSION"])},
  "Step2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Template"])},
  "Step2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a template and customize it to your business needs.This product is available for desktop as well as mobile users and can also be made into native apps if required."])},
  "Step3Subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["03 - GAMES"])},
  "Step3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Game"])},
  "Step3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collaborate with well-known gaming brands and take advantage of the opportunity to unite games from more than 25 platforms. As a result, you have access to thousands of games, ranging from traditional live baccarat to e-sports."])},
  "ProductAdvantages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Advantages"])},
  "ProductAdvantagesDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a dedicated team of technical experts who are robust, responsive and ready to deliver."])},
  "Advantages1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Stop Solution"])},
  "Advantages1Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipped with a specialized team that proactively provides you with a complete suite of products and services to start your gaming operation with your unique brand identity. Entertainment like never before with our single portal to create the richest gaming experience in this industry with all your desired achievements at your fingertips."])},
  "Advantages2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse gaming platforms"])},
  "Advantages2Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boost your revenues with our vast choice of casino content. Not only do we offer games from well established game providers such as Playtech, Asia Gaming and many more, but we are also expanding our offer as we are regulary adding new game providers and new games from the existing ones. Every new casino game is offered in an instant play allowing you to enjoy rich variety and top-quality 24/7."])},
  "Advantages3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple Agency Models"])},
  "Advantages3Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We apply the pyramid model with real-time billing layer by layer commission. Our site is not only user friendly, but also helps you to earn money in a very short time; the universal agency model allows all players to become a site agent, which is why this model has become the most popular marketing tool of modern times."])},
  "Advantages4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fast and Convenient Payment"])},
  "Advantages4Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support various payment methods, members can deposit quickly and conveniently with the highest level of security. The user-friendly interface and the increased flexibility in defining your limits allow you to manage your members&acute; deposit amounts effectively."])},
  "Advantages5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Platform Support"])},
  "Advantages5Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We support all types of computers as well as mobile devices and are perfectly compatible with Android and iOS systems. In addition, we offer a native app for better performance and best experience. Hot patch instant updates, no need of waiting to play games."])},
  "Advantages6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional Data Analysis"])},
  "Advantages6Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timely and accurate information to power your business. The back end functions are constantly updated to stay on the cutting edge of the industry. Our platform enables you to monitor and manage your player’s accounts, thoroughly analyze their behavior, and keep track of your revenues online for lifetime player value optimization."])},
  "Advantages7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Security"])},
  "Advantages7Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usage of Global Trust certified AES 256-bit encryption for high access rates and data security. With no downtime for maintenance and constant updates, you can enjoy a smoother and more stable web experience."])},
  "Advantages8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDN Acceleration Service"])},
  "Advantages8Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No need for you to worry about the application for the domain name and the websites set-up. All these tasks are taken care of by us for your satisfaction. With the CDN service, you can boost your website speed and optimize the stability of your system."])},
  "Advantages9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["24/7 Technical Support"])},
  "Advantages9Desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We have a professional and round-the-clock online technical support that takes care of all kinds of emergencies and offers solutions to all sorts of problems to expand your global success."])},
  "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
  "Slots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slots"])},
  "LiveCasino": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live Casino"])},
  "Sportsbook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportsbook"])},
  "eSabong": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eSabong"])},
  "eGames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eGames"])},
  "Web": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web"])},
  "Mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile"])}
}